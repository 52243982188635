import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import React from 'react';
import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';
import type {
	CellClickArgs,
	CellMouseEvent,
	Column,
	DataGridHandle,
	FillEvent,
} from 'react-data-grid';
import {ParagraphUpdateInput, RegulatoryDocumentParagraph} from 'types';
import {useTranslation} from 'react-i18next';
import {
	FontIcon,
	MarqueeSelection,
	Selection,
	ISelection,
	Stack,
	Toggle,
	Dialog,
	DialogType,
	Panel,
	PanelType,
} from '@fluentui/react';
import {useGetParagraphsFormDataQuery} from 'features/RegulatoryDocuments/hooks/useGetParagraphsFormData.generated';
import {ParagraphFormFields} from 'features/RegulatoryDocuments/components/DocumentDetails/EditParagraphsForm/EditParagraphsForm.types';
import {useForm} from 'react-hook-form';
import {initialRegulatoryDocumentParagraph} from 'features/RegulatoryDocuments/components/DocumentDetails/EditParagraphsForm/editParagraphsForm.constants';
import {useBeforeUnload, useNavigate} from 'react-router-dom';
import {useBoolean, useForceUpdate} from '@fluentui/react-hooks';
import {IRow, createRows} from './ParagraphRows';
import {
	getColumnsGridView,
	renderCheckbox,
	renderDataCell,
	renderDataEditCell,
	renderParagraphCell,
	mapFormEdgeNodes,
} from './ParagraphColumns';
import {useUpdateRegulatoryDocumentParagraphsMutation} from 'features/RegulatoryDocuments/hooks';
import {mapToRef} from 'helpers';
import {useCommand} from 'hooks';
import {ActionButton} from 'components';
import {getFilterContentGridView, GridViewFilter} from './ParagraphFilter';
import {getParagraphListBaseColumns} from '../DocumentDetails';

enum GridDialog {
	save = 'save',
	saveExit = 'saveExit',
	cancel = 'cancel',
	none = 'none',
}
interface IParagraphGridView {
	regulatoryDocumentId: string;
	paragraphsAll: RegulatoryDocumentParagraph[];
	paragraphsFiltered: RegulatoryDocumentParagraph[];
	setViewGrid: (viewGrid: boolean) => void;
}

interface IDict {
	[key: string]: any;
}

export const ParagraphGridView: React.FC<IParagraphGridView> = ({
	regulatoryDocumentId,
	paragraphsAll,
	paragraphsFiltered,
	setViewGrid,
}) => {
	const {t} = useTranslation();

	const {control, setValue, getValues} = useForm<ParagraphFormFields>({
		reValidateMode: 'onSubmit',
		mode: 'all',
		defaultValues: initialRegulatoryDocumentParagraph,
	});

	const {data: dataForm} = useGetParagraphsFormDataQuery();

	const [updateParagraphs, {data: dataSave, loading: savingProgress}] =
		useUpdateRegulatoryDocumentParagraphsMutation();

	const blockSize = 50;

	const [blockNumber, setBlockNumber] = useState(0);

	const [paragraphsState, setParagraphsState] =
		useState<RegulatoryDocumentParagraph[]>(paragraphsAll);

	const [selectedRows, setSelectedRows] = useState(
		(): ReadonlySet<number> => new Set(),
	);

	const [filters, setFilters] = React.useState<GridViewFilter[]>([]);

	const [rowHeight, setRowHeight] = useState(40);

	const [cellPosition, setCellPosition] = useState({idx: 0, rowIdx: 0});

	const [boolScroll, setBoolScroll] = useState(false);

	const [arrChangeParaId, setArrChangeParaId] = useState<string[]>([]);

	const [arrCommonIDInitial, setArrCommonIDInitial] = useState<string[]>([]);

	const [isBlocking, setIsBlocking] = useState(false);

	const [gridDialogType, setgridDialogType] = useState(GridDialog.none);

	const [isOpen, {setTrue: openPanel, setFalse: dismissPanel}] =
		useBoolean(false);

	const refGrid = useRef<DataGridHandle>(null);

	const paragraphsFilteredID = useMemo(
		() => paragraphsFiltered.map(p => p.id),
		[paragraphsFiltered],
	);

	const baseColumns = useMemo(() => getParagraphListBaseColumns(t, true), []);

	const paragraphsStateFiltered = useMemo(() => {
		const filterableColumns = baseColumns.filter(col => col.filterable);
		return paragraphsState
			.filter(p => paragraphsFilteredID.indexOf(p.id) !== -1)
			.filter(p => {
				const filterRes: boolean[] = filterableColumns.map(col => {
					const colFilters = filters.filter(f => f.columnKey === col.fieldName);

					if (!colFilters.length) {
						return true;
					}

					for (const filter of colFilters) {
						if (filter.filterFn(p)) {
							return true;
						}
					}
					return false;
				});
				return filterRes.reduce((res, curr) => res && curr, true);
			});
	}, [paragraphsState, paragraphsFilteredID, filters]);

	const visibleRows = useMemo<IRow[]>(() => {
		return createRows(paragraphsStateFiltered, blockSize * (blockNumber + 1));
	}, [paragraphsStateFiltered, blockNumber]);

	const forceUpdate = useForceUpdate();

	const navigate = useNavigate();

	const selection = React.useRef(
		(() => {
			const self = new Selection({
				items: visibleRows,
				getKey: item => item.rowId,
				onSelectionChanged() {
					setSelectedRows(new Set(self.getSelection().map(x => x.rowId)));
					forceUpdate();
				},
			});
			return self;
		})(),
	);

	useEffect(() => {
		if (refGrid.current && boolScroll) {
			refGrid.current.scrollToCell(cellPosition);
			setBoolScroll(false);
		}
	}, [refGrid.current, cellPosition]);

	useBeforeUnload(
		React.useCallback(
			e => {
				if (isBlocking && gridDialogType === GridDialog.none) {
					e.preventDefault();
				}
			},
			[isBlocking, gridDialogType],
		),
	);

	useEffect(() => {
		setBoolScroll(true);
		setCellPosition({idx: 0, rowIdx: 0});
		selection.current.setAllSelected(false);
		selection.current.setItems(visibleRows);
	}, [paragraphsFilteredID]);

	useEffect(() => {
		const arrOldSelected = selection.current.getSelection().map(x => x.rowId);

		selection.current.setItems(visibleRows);

		arrOldSelected.forEach(x =>
			selection.current.setIndexSelected(x, true, false),
		);
	}, [paragraphsFilteredID, visibleRows.length]);

	const arrArrayTypeColKeys = [
		'categories',
		'keywords',
		'tags',
		'vehicleCategories',
		'driveVariants',
	];

	function handleFill({
		columnKey,
		sourceRow,
		targetRow,
	}: FillEvent<IRow>): IRow {
		return {
			...targetRow,
			paragraph: {
				...targetRow.paragraph,
				[columnKey]:
					sourceRow.paragraph[columnKey as keyof RegulatoryDocumentParagraph],
			},
		};
	}

	async function handleScroll(event: React.UIEvent<HTMLDivElement>) {
		const boolInsideLimits =
			event.currentTarget.scrollTop + rowHeight <
			event.currentTarget.scrollHeight - event.currentTarget.clientHeight;

		if (boolInsideLimits) return;

		if (paragraphsStateFiltered.length > blockNumber * blockSize) {
			setBlockNumber(blockNumber + 1);
		}
	}

	const handleBatchSave = useCallback(async () => {
		const selectedItems = paragraphsState.filter(
			p => arrChangeParaId.indexOf(p.id) !== -1,
		);
		if (selectedItems.length > 0) {
			const inputParagraphs = {
				regulatoryDocumentId,
				paragraphUpdates: selectedItems.map(p => {
					return {
						paragraphId: p.id,
						update: {
							vehicleCategoryRefs: mapToRef(p.vehicleCategories),
							keywordRefs: mapToRef(p.keywords),
							driveVariantRefs: mapToRef(p.driveVariants),
							categoryRefs: mapToRef(p.categories),
							tagRefs: mapToRef(p.tags),
							comprehensive: p.comprehensive,
							dateNewRegistration: p.dateNewRegistration,
							dateNewTypes: p.dateNewTypes,
							modelYear: p.modelYear,
							phaseIn: (p.phaseIn ?? []).map(pi => {
								return {date: pi.date, status: pi.status};
							}),
							phaseOut: (p.phaseOut ?? []).map(pi => {
								return {date: pi.date, status: pi.status};
							}),
						},
					} as ParagraphUpdateInput;
				}),
			};

			await updateParagraphs({
				variables: {
					input: inputParagraphs,
				},
			});

			setArrChangeParaId([]);
			setIsBlocking(false);
		}
	}, [paragraphsState, arrChangeParaId]);

	const handleDismiss = useCallback(
		(name: string, row: IRow) => {
			return function () {
				setIsBlocking(true);
				const selectedItems = selection.current.getSelection();

				if (selectedItems.length > 1) {
					const newInputData = getValues(name as keyof ParagraphFormFields);
					const arrSelectedID = selectedItems.map(r => r.paragraph.id);
					setArrChangeParaId([...arrChangeParaId, ...arrSelectedID]);

					if (arrArrayTypeColKeys.indexOf(name) >= 0) {
						const arrCommonIDNew = newInputData
							? (newInputData as any[]).map(x => x.id)
							: [];

						const arrDeleteID = arrCommonIDInitial.filter(
							x => arrCommonIDNew.indexOf(x) === -1,
						);

						const newInputDataAdd = newInputData
							? (newInputData as any[]).filter(
									x => arrCommonIDInitial.indexOf(x.id) === -1,
							  )
							: [];

						setParagraphsState(
							paragraphsState.map(p => {
								if (arrSelectedID.indexOf(p.id) !== -1) {
									const arrFieldValID = p[
										name as keyof RegulatoryDocumentParagraph
									].map((x: {id: any}) => x.id);

									const newInputDataAddTrue = newInputDataAdd.filter(
										x => arrFieldValID.indexOf(x.id) === -1,
									);

									const newInputDataFiltered = p[
										name as keyof RegulatoryDocumentParagraph
									].filter(
										(x: {id: string}) => arrDeleteID.indexOf(x.id) === -1,
									);

									const newInputDataTotal =
										newInputDataFiltered.concat(newInputDataAddTrue);

									return {
										...p,
										[name as keyof RegulatoryDocumentParagraph]:
											newInputDataTotal,
									};
								}
								return p;
							}),
						);
					} else {
						setParagraphsState(
							paragraphsState.map(p => {
								if (arrSelectedID.indexOf(p.id) !== -1) {
									return {
										...p,
										[name as keyof RegulatoryDocumentParagraph]: newInputData,
									};
								}
								return p;
							}),
						);
					}
				} else {
					setArrChangeParaId([...arrChangeParaId, row.paragraph.id]);
					setParagraphsState(
						paragraphsState.map(p => {
							if (p.id === row.paragraph.id) {
								return {
									...p,
									[name as keyof RegulatoryDocumentParagraph]: getValues(
										name as keyof ParagraphFormFields,
									),
								};
							}
							return p;
						}),
					);
				}
			};
		},
		[
			paragraphsState,
			selection.current.getSelectedIndices(),
			arrCommonIDInitial,
		],
	);

	const handleCellDoubleClick = useCallback(
		(clickArg: CellClickArgs<IRow>, event: CellMouseEvent) => {
			const formFieldName = clickArg.column.key as keyof ParagraphFormFields;
			const dataFieldName = clickArg.column
				.key as keyof RegulatoryDocumentParagraph;

			const selectedItems = selection.current.getSelection();

			if (
				selectedItems.length > 1 &&
				arrArrayTypeColKeys.indexOf(clickArg.column.key) !== -1
			) {
				const arrSelectedID = selectedItems.map(r => r.paragraph.id);
				const arrSelectedParagraphs = paragraphsState.filter(
					p => arrSelectedID.indexOf(p.id) !== -1,
				);
				const matrixID: string[][] = arrSelectedParagraphs.map(p =>
					p[dataFieldName].map((x: {id: string}) => x.id),
				);

				const arrCommonID = matrixID[0].reduce(function (res: string[], v) {
					if (
						res.indexOf(v) === -1 &&
						matrixID.every(function (a) {
							return a.indexOf(v) !== -1;
						})
					)
						res.push(v);
					return res;
				}, []);

				const dataAuto = arrSelectedParagraphs[0][dataFieldName].filter(
					(x: {id: string}) => arrCommonID.indexOf(x.id) !== -1,
				);
				setArrCommonIDInitial(arrCommonID);
				setValue(formFieldName, dataAuto);
			} else {
				setValue(formFieldName, clickArg.row.paragraph[dataFieldName]);
			}

			if (clickArg.column.key === 'paragraph') {
				setBoolScroll(true);
				if (rowHeight === 40) {
					setRowHeight(240);
				} else {
					setRowHeight(40);
				}
			}
		},
		[paragraphsState, selection.current.getSelection(), rowHeight],
	);

	const handleRowChange = useCallback(
		(rows: IRow[], data: {column: Column<IRow>; indexes: number[]}) => {
			setIsBlocking(true);
			const arrRowIDs = data.indexes;

			const arrSelectedID = arrRowIDs.map(x => rows[x].paragraph.id);

			const arrSelected = arrRowIDs.map(x => rows[x].paragraph);

			setArrChangeParaId([...arrChangeParaId, ...arrSelectedID]);

			setParagraphsState(
				paragraphsState.map(p => {
					const intID = arrSelectedID.indexOf(p.id);
					if (intID !== -1) {
						return {
							...p,
							[data.column.key as keyof RegulatoryDocumentParagraph]:
								arrSelected[intID][
									data.column.key as keyof ParagraphFormFields
								],
						};
					}
					return p;
				}),
			);
		},
		[paragraphsState],
	);

	const dicFormData: IDict = useMemo(() => {
		const {
			driveVariantData,
			vehicleCategorieData,
			categoriesData,
			keywordData,
			tagData,
		} = mapFormEdgeNodes(dataForm);
		return {
			categories: categoriesData,
			keywords: keywordData,
			tags: tagData,
			vehicleCategories: vehicleCategorieData,
			driveVariants: driveVariantData,
		};
	}, [dataForm]);

	const fnCheckbox = useCallback(
		() => renderCheckbox(selection),
		[selection.current.getSelection()],
	);

	const fnParagraphCell = useCallback(() => renderParagraphCell(t), []);

	const fnDataCell = useCallback(
		(name: string, fieldType: string) => renderDataCell(name, fieldType),
		[],
	);

	const fnEditDataCell = useCallback(
		(name: string, tname: string, fieldType: string, colNumber: number) => {
			const arrItems = dicFormData[name as string] ?? [];
			return renderDataEditCell(
				name,
				tname,
				fieldType,
				colNumber,
				handleDismiss,
				control,
				t,
				arrItems,
			);
		},
		[dicFormData, handleDismiss, control],
	);

	const columns = useMemo(
		() =>
			getColumnsGridView(
				fnCheckbox,
				fnParagraphCell,
				fnDataCell,
				fnEditDataCell,
				t,
			),
		[fnCheckbox, fnParagraphCell, fnDataCell, fnEditDataCell],
	);

	const filterContent = useMemo(() => {
		return getFilterContentGridView(
			baseColumns,
			paragraphsState,
			filters,
			setFilters,
			t,
		);
	}, [paragraphsState, filters]);

	useCommand(
		{
			key: 'gridView',
			text: 'Rasteransicht beenden',
			onClick() {
				if (arrChangeParaId.length === 0) {
					navigate(0);
				} else {
					setgridDialogType(GridDialog.saveExit);
				}
			},
			farCommand: true,
			priority: 0,
			iconProps: {
				iconName: 'GridViewSmall',
			},
		},
		[arrChangeParaId],
	);

	useCommand(
		{
			key: 'filter',
			priority: 1,
			iconProps: {
				iconName: 'Filter',
			},
			farCommand: true,
			onClick: openPanel,
			ariaLabel: 'Filter',
			title: 'Filter',
		},
		[],
	);

	useCommand(
		{
			key: 'metadataSave',
			text: 'Metadaten speichern',
			onClick() {
				setgridDialogType(GridDialog.save);
			},
			priority: 0,
			iconProps: {
				iconName: 'Save',
			},
		},
		[],
	);

	useCommand(
		{
			key: 'cancelChanges',
			text: 'Abbrechen',
			onClick() {
				if (arrChangeParaId.length === 0) {
					navigate(0);
				} else {
					setgridDialogType(GridDialog.cancel);
				}
			},
			farCommand: false,
			priority: 1,
			iconProps: {
				iconName: 'Cancel',
			},
		},
		[arrChangeParaId],
	);

	return (
		<>
			<Stack>
				<Stack.Item>
					<Stack horizontal horizontalAlign='space-between'>
						<Stack.Item align='end'>
							<Toggle
								inlineLabel
								checked={rowHeight !== 40}
								onText='detailierte Ansicht'
								offText='Kompaktansicht'
								onChange={() => {
									setBoolScroll(true);
									if (rowHeight === 40) {
										setRowHeight(240);
									} else {
										setRowHeight(40);
									}
								}}
							/>
						</Stack.Item>
						<Stack.Item align='end'>
							{arrChangeParaId.length === 0 && !savingProgress && (
								<FontIcon
									aria-label='Cloud'
									iconName='Cloud'
									style={{fontSize: 50, background: 'lightgreen'}}
								/>
							)}

							{arrChangeParaId.length > 0 && !savingProgress && (
								<FontIcon
									aria-label='TripleColumnEdit'
									iconName='TripleColumnEdit'
									style={{fontSize: 40, background: 'orange'}}
								/>
							)}
						</Stack.Item>
					</Stack>
				</Stack.Item>

				<Stack.Item>
					<MarqueeSelection
						selection={selection.current as unknown as ISelection}
						isEnabled={true}
						onShouldStartSelection={e => e.shiftKey || e.ctrlKey}
					>
						<DataGrid
							onCellKeyDown={(clickArg, e) => {
								if (e.key && e.key === 'Escape') {
									selection.current.setAllSelected(false);
								}
							}}
							columns={columns}
							rows={visibleRows}
							onRowsChange={handleRowChange}
							rowKeyGetter={row => row.rowId}
							headerRowHeight={40}
							rowHeight={rowHeight}
							onScroll={handleScroll}
							className='rdg-light'
							onCellClick={clickArg =>
								setCellPosition({
									idx: clickArg.column.idx,
									rowIdx: clickArg.row.rowId,
								})
							}
							onCellDoubleClick={handleCellDoubleClick}
							selectedRows={selectedRows}
							onFill={handleFill}
							onSelectedRowsChange={setSelectedRows}
							style={{height: '80vh', userSelect: 'none'}}
							ref={refGrid}
						/>
					</MarqueeSelection>
				</Stack.Item>
				<Stack.Item align='end'>
					<div key='summary'>
						{paragraphsAll.length === paragraphsFilteredID.length
							? `Anzahl der Segmente: ${paragraphsAll.length} (davon ausgewählt: ${selection.current.count})`
							: `Anzahl der gefilterten Segmente: ${paragraphsFilteredID.length} (davon ausgewählt: ${selection.current.count} )`}
					</div>
				</Stack.Item>
			</Stack>
			<Panel
				type={PanelType.medium}
				isLightDismiss
				isOpen={isOpen}
				onDismiss={dismissPanel}
				closeButtonAriaLabel='Close'
				headerText={t('Filters')}
				isFooterAtBottom={true}
			>
				{filterContent}
			</Panel>
			<Dialog
				hidden={gridDialogType === GridDialog.none}
				modalProps={{isBlocking: false}}
				onDismiss={() => {
					setgridDialogType(GridDialog.none);
				}}
				dialogContentProps={{
					type: DialogType.normal,
					title:
						gridDialogType === GridDialog.save
							? 'titleSave'
							: gridDialogType === GridDialog.saveExit
							? 'titleSaveExit'
							: 'titleCancel',
					subText:
						gridDialogType === GridDialog.save
							? 'textSave'
							: gridDialogType === GridDialog.saveExit
							? 'textSaveExit'
							: 'textCancel',
				}}
			>
				{gridDialogType === GridDialog.save && (
					<ActionButton
						onClick={async () => {
							await handleBatchSave();
							setgridDialogType(GridDialog.none);
						}}
					>
						{'Änderungen speichern'}
					</ActionButton>
				)}
				{gridDialogType === GridDialog.saveExit && (
					<Stack horizontal horizontalAlign='space-between'>
						<ActionButton
							onClick={async () => {
								await handleBatchSave();
								navigate(0);
							}}
						>
							{'Änderungen speichern'}
						</ActionButton>
						<ActionButton
							onClick={async () => {
								navigate(0);
							}}
						>
							{'Änderungen verwerfen'}
						</ActionButton>
					</Stack>
				)}
				{gridDialogType === GridDialog.cancel && (
					<ActionButton
						onClick={async () => {
							navigate(0);
						}}
					>
						{'Änderungen verwerfen'}
					</ActionButton>
				)}
			</Dialog>
		</>
	);
};
