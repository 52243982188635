import {
	ChoiceGroup,
	DefaultButton,
	Dropdown,
	IButtonStyles,
	IChoiceGroupOption,
	IScrollablePaneStyles,
	IStackStyles,
	IconButton,
	Panel,
	PanelType,
	Pivot,
	PivotItem,
	PrimaryButton,
	ScrollablePane,
	SearchBox,
	SelectionMode,
	Separator,
	Stack,
	Text,
	Theme,
	Toggle,
	mergeStyleSets,
	useTheme,
} from '@fluentui/react';
import {useBoolean} from '@fluentui/react-hooks';
import {useUserContext} from 'authentication/UserContext';
import {ActionButton, EntityList, EntityListColumn} from 'components';
import {AttachmentsField} from 'components/AttachmentsField';
import {renderParagraph} from 'components/EntityList/ColumnRenderers';
import {EntityNameField} from 'components/EntityNameField';
import {KeywordFields} from 'components/Keywords/keywordItem.types';
import {LoadWrapper} from 'components/LoadWrapper';
import {useGetConvolutesQuery} from 'features/AdminSection/hooks/convolutes.generated';
import {useGetGdprRuleQuery} from 'features/AdminSection/hooks/gdprRules.generated';
import {useGetAllKeywordsQuery} from 'features/AdminSection/hooks/keywords.generated';
import {useGetAllRegulationsQuery} from 'features/Regulations/hooks';
import {RegulatoryDetailsPageFormMode} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.types';
import {useRegulatoryDocumentsContext} from 'features/RegulatoryDocuments/context';
import {useAssignRegulatoryDocumentParagraphsRequirementsMutation} from 'features/RegulatoryDocuments/hooks/useAssignRegulatoryDocumentParagraphsRequirement.generated';
import {useCreateRequirementMutation} from 'features/RegulatoryDocuments/hooks/useCreateRequirement.generated';
import {
	GetAddRequirementsFormDataQuery,
	useGetAddRequirementsFormDataQuery,
} from 'features/RegulatoryDocuments/hooks/useGetAddRequirementsFormData.generated';
import {GetRegulatoryDocumentDetailsDocument} from 'features/RegulatoryDocuments/hooks/useGetRegulatoryDocumentDetails.generated';
import {
	GetRegulatoryDocumentsWithParagraphsQuery,
	useGetRegulatoryDocumentsWithParagraphsLazyQuery,
} from 'features/RegulatoryDocuments/hooks/useGetRegulatoryDocumentsWithParagraphs.generated';
import {
	useGetRequirementsWithAllocationLazyQuery,
	useGetRequirementsWithoutAllocationLazyQuery,
} from 'features/RegulatoryDocuments/hooks/useGetRequirementsAllocation.generated';
import {RequirementUtilsService} from 'features/Requirements/RequirementUtils.service';
import {RequirementsTooltipTranslationProvider} from 'features/Requirements/RequirementsTooltipTranslationProvider';
import {
	CommonFormElementsOfRequirements,
	CompatibleFormFields,
} from 'features/Requirements/components/CommonFormElementsOfRequirements';
import {useUpdateRequirementAttachmentsMutation} from 'features/Requirements/hooks/requirementAttachments.generated';
import {useGetRequirementsFormDataQuery} from 'features/Requirements/hooks/requirements.generated';
import {useRequirementColumns} from 'features/Requirements/hooks/useRequirementColumns';
import {useRequirementsTranslation} from 'features/Requirements/hooks/useRequirementsTranslation';
import {ProviderThatEnablesGettingTooltipsFromContext} from 'features/localizedTooltips';
import {mapToRef} from 'helpers';
import {
	useCommand,
	useFileUpload,
	useNotificationBar,
	useSelection,
} from 'hooks';
import {uniqBy} from 'lodash';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {
	ConvoluteType,
	DriveVariant,
	RegulatoryDocumentParagraph,
	Requirement,
	RequirementStatus,
	UserRole,
	VehicleCategory,
} from 'types';
import {useParagraphsContext} from './ParagraphsContext';
import {useCloneRequirement} from 'features/Requirements/hooks/useCloneRequirement';
import {QueryResult} from '@apollo/client';
import {useRequirementsVersions} from 'features/Requirements/hooks/useRequirementsVersions';

type FilterOptions = {
	showMyRequirements: boolean;
	filterByRegulation: boolean;
	filterByKeyword: boolean;
	keyword?: string;
	searchValue?: string;
};

type QueryRequirement = GetAddRequirementsFormDataQuery['requirements'][number];

/**
 * We use this to verify that the requirement has the necessary data to render
 * the Entity List columns' values.
 */
interface RequirementForEntityList extends Omit<QueryRequirement, 'keywords'> {
	keywords: KeywordFields[];
}

const {createCommonCreationInputFields} = new RequirementUtilsService();

type PossibleRegDocData =
	QueryResult<GetRegulatoryDocumentsWithParagraphsQuery>['data'];

export const AssignRequirementsForm = () => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'AssignRequirementsForm',
	});

	const {t: getRequirementsTranslation} = useRequirementsTranslation();

	const theme = useTheme();
	const classNames = getClassNames(theme);

	const [assignRegulatoryDocumentParagraphsRequirements] =
		useAssignRegulatoryDocumentParagraphsRequirementsMutation();
	const [createRequirement] = useCreateRequirementMutation();
	const [updateAttachments] = useUpdateRequirementAttachmentsMutation();
	const {cloneRequirement} = useCloneRequirement();
	const [showFilter, setShowFilter] = React.useState(false);
	const [keyword, setKeyword] = React.useState<string | number>();
	const [searchValue, setSearchValue] = React.useState('');

	const {selectedParagraphs} = useParagraphsContext();
	const {selectedRegulatoryDocument} = useRegulatoryDocumentsContext();

	type FormFields = CompatibleFormFields & Pick<Requirement, 'name'>;

	const {handleSubmit, control, reset, setValue} = useForm<FormFields>({
		reValidateMode: 'onSubmit',
		mode: 'all',
	});

	const [mode, setMode] = React.useState<RegulatoryDetailsPageFormMode>(
		RegulatoryDetailsPageFormMode.None,
	);
	const [
		createNewMode,
		{setTrue: changeToCreateMode, setFalse: changeToAssignMode},
	] = useBoolean(false);

	React.useEffect(() => {
		reset({});
	}, [selectedParagraphs]);

	const {data, loading} = useGetAddRequirementsFormDataQuery();
	const [
		getReqWithAllocationLazy,
		{
			data: requirementsWithAllocation,
			loading: loadingRequirementsWithAllocation,
		},
	] = useGetRequirementsWithAllocationLazyQuery();
	const [
		getReqWithoutAllocationLazy,
		{
			data: requirementsWithoutAllocation,
			loading: loadingRequirementsWithoutAllocation,
		},
	] = useGetRequirementsWithoutAllocationLazyQuery();
	const {data: regulationData} = useGetAllRegulationsQuery();
	const {data: keywordData} = useGetAllKeywordsQuery();
	const {loading: formDataLoading, data: formData} =
		useGetRequirementsFormDataQuery();
	const {filterOutPastMinorVersionOfRequirements} = useRequirementsVersions();

	const [getRegDocs, {data: regDocData}] =
		useGetRegulatoryDocumentsWithParagraphsLazyQuery();

	const {data: convolutes} = useGetConvolutesQuery();
	const convolute = React.useMemo(
		() =>
			convolutes?.convolutes?.find(
				c => c.convoluteType === ConvoluteType.Requirement,
			),
		[convolutes],
	);

	const {isAdmin} = useUserContext();

	const {setMessage} = useNotificationBar();

	const {data: gdprRule} = useGetGdprRuleQuery({
		variables: {
			id: convolute?.gdprRule?.id ?? '',
		},
	});

	const rule = React.useMemo(() => gdprRule?.gdprRule, [gdprRule]);

	const {myVexClusters} = useUserContext();

	const requirementData: QueryRequirement[] = React.useMemo(
		() => data?.requirements ?? [],
		[data],
	);

	const requirementDataWithAllocation: QueryRequirement[] = React.useMemo(
		() => requirementsWithAllocation?.requirementsWithAllocation ?? [],
		[requirementsWithAllocation],
	);

	const requirementDataWithoutAllocation: QueryRequirement[] = React.useMemo(
		() => requirementsWithoutAllocation?.requirementsWithoutAllocation ?? [],
		[requirementsWithoutAllocation],
	);

	const keywordOptions = React.useMemo(() => {
		const options =
			keywordData?.keywords?.map(k => {
				return {
					key: k.id,
					text: k.name,
				};
			}) ?? [];
		options.unshift({key: '', text: ''});
		return options;
	}, [keywordData]);

	const regulationOptions = React.useMemo(() => {
		const options =
			regulationData?.regulations.map(r => {
				return {key: r.id, text: r.regulationNumber};
			}) ?? [];
		options.unshift({key: '', text: ''});
		return options;
	}, [regulationData]);

	const [filterByKeyword, setFilterByKeyword] = React.useState(false);
	const [filterByRegulation, setFilterByRegulation] = React.useState(false);
	const [showMyRequirements, setShowMyRequirements] = React.useState(false);

	const [selectedRequirements, setSelectedRequirements] = React.useState<
		Requirement[]
	>([]);

	const [selection] = useSelection<Requirement>({
		onSelectionChanged(selectedItems) {
			setSelectedRequirements(selectedItems);
		},
		getKey: item => item.id,
	});

	const {attachments, FileUploadComponent} = useFileUpload(undefined);

	const onRequirementCreated = React.useCallback(
		async (createRes: any) => {
			const createdRequirementId =
				createRes.data?.createRequirement.requirement?.id;
			if (createdRequirementId) {
				if (attachments) {
					await updateAttachments({
						variables: {
							input: {
								attachments: attachments.map(f => ({
									attachmentId: f.file.name,
									file: f.file,
									categoryId: f.attachmentCategoryId,
								})),
								requirementId: createdRequirementId,
							},
						},
					});
				}

				await assignRegulatoryDocumentParagraphsRequirements({
					variables: {
						input: {
							regulatoryDocumentId: selectedRegulatoryDocument?.id ?? '',
							requirementRefs: [{id: createdRequirementId}],
							paragraphIds: selectedParagraphs?.map(p => p.id) ?? [],
						},
					},
					refetchQueries: [GetRegulatoryDocumentDetailsDocument],
				});
			}
		},
		[attachments, selectedParagraphs, selectedRegulatoryDocument],
	);

	const handleRequirementVersioning = React.useCallback(async () => {
		const newRequirements = selectedRequirements.filter(req => {
			if (req.status !== RequirementStatus.Final) return false;
			return selectedParagraphs.some(sp => {
				return sp.requirements.findIndex(spReq => spReq.id === req.id) === -1;
			});
		});
		/* eslint-disable no-await-in-loop */
		for (const nr of newRequirements) {
			await cloneRequirement(nr, [GetRegulatoryDocumentDetailsDocument], rule);
		}
	}, [
		selectedRequirements,
		selectedParagraphs,
		cloneRequirement,
		GetRegulatoryDocumentDetailsDocument,
	]);

	const handleSaveClick = React.useCallback(async () => {
		await handleSubmit(async formFields => {
			if (createNewMode) {
				await createRequirement({
					variables: {
						input: createCommonCreationInputFields(formFields, rule),
					},
				}).then(onRequirementCreated);
			} else {
				const assignRet = await assignRegulatoryDocumentParagraphsRequirements({
					variables: {
						input: {
							regulatoryDocumentId: selectedRegulatoryDocument?.id ?? '',
							requirementRefs: mapToRef(selectedRequirements),
							paragraphIds: selectedParagraphs?.map(p => p.id) ?? [],
						},
					},
					refetchQueries: [GetRegulatoryDocumentDetailsDocument],
				});
				if (assignRet.data) {
					await handleRequirementVersioning();
				}
			}

			setMode(RegulatoryDetailsPageFormMode.None);
			selection.setAllSelected(false);
			setFilterByKeyword(false);
			setFilterByRegulation(false);
			setSearchValue('');
			setKeyword(undefined);
			setShowMyRequirements(false);
			setMessage(
				t('AssignRequirementUpdated', {
					selectedParagraphs: selectedParagraphs.length.toString(),
				}),
			);
			reset({});
		})();
	}, [
		mode,
		createNewMode,
		selectedRequirements,
		selectedParagraphs,
		selectedRegulatoryDocument,
		attachments,
		rule,
	]);

	const handleCancelClick = React.useCallback(() => {
		setMode(RegulatoryDetailsPageFormMode.None);
		setFilterByKeyword(false);
		setFilterByRegulation(false);
		setSearchValue('');
		setKeyword(undefined);
		setShowMyRequirements(false);
		reset({});
	}, []);

	const onPivotItemClick = (item?: PivotItem) => {
		if (item?.props.itemKey === 'assign') {
			changeToAssignMode();
		} else if (item?.props.itemKey === 'create') {
			changeToCreateMode();
		}
	};

	const handleOnClear = React.useCallback(() => {
		setSearchValue('');
	}, []);

	const onRenderFooterContent: any = React.useCallback(
		() => (
			<form>
				<ActionButton styles={buttonStyles} onClick={handleSaveClick}>
					{createNewMode
						? t('CreateRequirementButton')
						: t('AssignRequirementButton', {
								count: selectedRequirements.length,
						  })}
				</ActionButton>
				<DefaultButton onClick={handleCancelClick}>
					{t('CancelButton')}
				</DefaultButton>
			</form>
		),
		[
			selectedRequirements,
			createNewMode,
			attachments,
			handleSaveClick,
			handleCancelClick,
		],
	);

	const [searchParams] = useSearchParams();

	const filter = searchParams.get('filter') ?? '';
	/* Requirment button, Regulatory Document */
	useCommand(
		{
			key: 'assign',
			text: t('Assign'),
			priority: 1,
			iconProps: {
				iconName: 'Add',
			},
			disabled: selectedParagraphs.length === 0,
			hidden: filter === 'new' && !isAdmin,
			onClick: () => setMode(RegulatoryDetailsPageFormMode.Assign),
			roles: [UserRole.SystemAdministrator, UserRole.Vex],
		},
		[mode, selectedParagraphs, isAdmin, filter],
	);

	const columns: EntityListColumn[] = useRequirementColumns();

	const paragraphsWithTags = React.useMemo(
		() => (selectedParagraphs?: RegulatoryDocumentParagraph[]) =>
			selectedParagraphs?.map(selectedParagraph => (
				<Stack key={selectedParagraph.id}>
					{renderParagraph(selectedParagraph, t)}
					<div className={classNames.requirementsWrapper}>
						{selectedParagraph.requirements?.map(requirement => (
							<Text
								className={classNames.requirement}
								key={`${selectedParagraph.id}${requirement.id}`}
							>
								{requirement.name}
							</Text>
						))}
					</div>
					<Separator />
				</Stack>
			)),
		[selectedParagraphs],
	);

	const options: IChoiceGroupOption[] = [
		{key: 'A', text: t('AllRequirements')},
		{key: 'B', text: t('RequirementsWithoutAllocation')},
		{key: 'C', text: t('RequirementsWithAllocation')},
	];

	const [selectedKey, setSelectedKey] = React.useState<string | undefined>('A');

	const onChange = React.useCallback(
		(ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
			switch (option.key) {
				case 'B':
					getReqWithoutAllocationLazy();
					break;
				case 'C':
					getReqWithAllocationLazy();
					break;
				default:
					break;
			}
			setSelectedKey(option.key);
		},
		[],
	);

	const setFilteredData = React.useCallback((): RequirementForEntityList[] => {
		let data = requirementData;
		if (selectedKey === 'B') {
			data = requirementDataWithoutAllocation;
		}

		if (selectedKey === 'C') {
			data = requirementDataWithAllocation;
		}

		return filterOutPastMinorVersionOfRequirements(data) as any;
	}, [
		selectedKey,
		requirementData,
		requirementDataWithoutAllocation,
		requirementDataWithAllocation,
	]);

	const onAggregateFromParagraphsClick = React.useCallback(() => {
		const driveVariants: DriveVariant[] = uniqBy(
			selectedParagraphs.flatMap(p => p.driveVariants),
			p => p.id,
		);
		const vehicleCategories: VehicleCategory[] = uniqBy(
			selectedParagraphs.flatMap(p => p.vehicleCategories),
			p => p.id,
		);

		setValue('driveVariants', driveVariants);
		setValue('vehicleCategories', vehicleCategories);
	}, [selectedParagraphs]);

	const reqs: RequirementForEntityList[] = React.useMemo(
		() =>
			filterRequirements(setFilteredData(), myVexClusters, regDocData, {
				showMyRequirements,
				filterByRegulation,
				filterByKeyword,
				keyword,
				searchValue,
			} as any),
		[
			setFilteredData,
			myVexClusters,
			regDocData,
			keyword,
			showMyRequirements,
			filterByKeyword,
			filterByRegulation,
			searchValue,
			selectedKey,
		],
	);
	return (
		<Panel
			type={PanelType.extraLarge}
			isFooterAtBottom={true}
			isOpen={mode === RegulatoryDetailsPageFormMode.Assign}
			onDismiss={handleCancelClick}
			closeButtonAriaLabel='Close'
			onRenderFooterContent={onRenderFooterContent}
		>
			<LoadWrapper
				loading={
					loading ||
					formDataLoading ||
					loadingRequirementsWithAllocation ||
					loadingRequirementsWithoutAllocation
				}
			>
				<Stack horizontal tokens={tokens} styles={{root: {height: '100%'}}}>
					<Stack styles={paragraphStackStyles} tokens={tokens} wrap>
						<ScrollablePane styles={selectedParagraphsScrollablePaneStyles}>
							<Stack.Item>
								<Text variant='xLarge'>{t('SelectedParagraphsHeader')}</Text>
							</Stack.Item>
							<Stack.Item>{paragraphsWithTags(selectedParagraphs)}</Stack.Item>
						</ScrollablePane>
					</Stack>
					<Stack styles={requirementStackStyles} tokens={tokens}>
						<ScrollablePane styles={assignRequirementsScrollablePaneStyles}>
							<Stack.Item>
								<Text variant='xLarge'>{t('AssignRequirementsHeader')}</Text>
							</Stack.Item>
							<Pivot
								onLinkClick={onPivotItemClick}
								defaultSelectedKey={createNewMode ? 'create' : 'assign'}
							>
								<PivotItem
									headerText={t('ExistingRequirements')}
									itemKey='assign'
								>
									<Stack tokens={tokens}>
										<Stack horizontal tokens={tokens}>
											<Stack.Item styles={iconButtonStyles}>
												<IconButton
													iconProps={{
														iconName: 'QueryList',
													}}
													id={'filterButton'}
													onClick={() => setShowFilter(!showFilter)}
												/>
											</Stack.Item>
											<Stack.Item styles={searchboxStyles}>
												<SearchBox
													placeholder={t('SearchPlaceholder')}
													onSearch={value => {
														setSearchValue(value);
													}}
													onClear={handleOnClear}
												/>
											</Stack.Item>
										</Stack>
										{showFilter && (
											<Stack>
												<Stack.Item>
													<Toggle
														label={t('MyRequirements')}
														checked={showMyRequirements}
														onChange={() =>
															setShowMyRequirements(!showMyRequirements)
														}
													/>
												</Stack.Item>
												<Stack.Item>
													<ChoiceGroup
														defaultSelectedKey='A'
														options={options}
														onChange={onChange as any}
														selectedKey={selectedKey}
													/>
												</Stack.Item>
												<Stack.Item>
													<Dropdown
														label={t('Regulation')}
														options={regulationOptions}
														onChange={(_, item) => {
															if (item?.key) {
																setFilterByRegulation(true);
																getRegDocs({
																	variables: {
																		regulationId: item.key as string,
																	},
																});
															}

															if (item?.key === '') {
																setFilterByRegulation(false);
															}
														}}
													/>
												</Stack.Item>
												<Stack.Item>
													<Dropdown
														label={t('Keywords')}
														options={keywordOptions}
														onChange={(_, item) => {
															if (item?.key) {
																setFilterByKeyword(true);
																setKeyword(item.key);
															}

															if (item?.key === '') {
																setFilterByKeyword(false);
																setKeyword('');
															}
														}}
													/>
												</Stack.Item>
											</Stack>
										)}
										<Stack.Item>
											<RequirementsTooltipTranslationProvider>
												<EntityList
													items={reqs}
													columns={columns}
													aria-rowcount
													selectionMode={SelectionMode.multiple}
													selection={selection}
													sticky={true}
												/>
											</RequirementsTooltipTranslationProvider>
										</Stack.Item>
									</Stack>
								</PivotItem>
								<PivotItem headerText={t('CreateRequirement')} itemKey='create'>
									<Stack tokens={tokens}>
										<RequirementsTooltipTranslationProvider>
											<ProviderThatEnablesGettingTooltipsFromContext>
												<Stack.Item>
													<EntityNameField
														control={control}
														t={getRequirementsTranslation}
														labelTranslationKey='Name'
														translationKeyOfRequiredMsg='RequiredField'
													/>
												</Stack.Item>
												<CommonFormElementsOfRequirements
													control={control}
													formData={formData}
													selectableVexClusters={myVexClusters}
													id={null}
													aggregationBtn={
														<PrimaryButton
															onClick={onAggregateFromParagraphsClick}
															styles={aggregateButtonStyles}
														>
															{t('AggregateFromParagraphs')}
														</PrimaryButton>
													}
												/>
												<Separator />
												<Stack.Item>
													<AttachmentsField
														t={t}
														FileUploadComponent={FileUploadComponent}
													/>
												</Stack.Item>
											</ProviderThatEnablesGettingTooltipsFromContext>
										</RequirementsTooltipTranslationProvider>
									</Stack>
								</PivotItem>
							</Pivot>
						</ScrollablePane>
					</Stack>
				</Stack>
			</LoadWrapper>
		</Panel>
	);
};

function filterRequirements(
	requirementData: RequirementForEntityList[],
	myVexClusters: {id: string}[],
	regDocData: PossibleRegDocData,
	filterOptions: FilterOptions,
): RequirementForEntityList[] {
	let filteredRequirements = requirementData;

	if (filterOptions.showMyRequirements) {
		filteredRequirements = filteredRequirements.filter(requirement =>
			requirement.vexClusters.some(vexCluster =>
				myVexClusters.some(cluster => cluster.id === vexCluster.id),
			),
		);
	}

	if (filterOptions.filterByRegulation && regDocData) {
		filteredRequirements = filteredRequirements.filter(requirement =>
			regDocData.regulation?.regulatoryDocuments?.some(regDoc =>
				regDoc.paragraphs.some(paragraph =>
					paragraph.requirements.some(req => req.id === requirement.id),
				),
			),
		);
	}

	if (filterOptions.filterByKeyword && filterOptions.keyword) {
		filteredRequirements = filteredRequirements.filter(requirement =>
			requirement.keywords.some(k => k.id === filterOptions.keyword),
		);
	}

	if (filterOptions.searchValue && filterOptions.searchValue.length > 0) {
		filteredRequirements = filteredRequirements.filter(
			requirement =>
				requirement.name
					.toLowerCase()
					.indexOf((filterOptions.searchValue as any).toLowerCase()) > -1,
		);
	}

	return filteredRequirements;
}

const getClassNames = (theme: Theme) =>
	mergeStyleSets({
		requirementsWrapper: {
			display: 'flex !important',
			flexWrap: 'wrap',
		},
		requirement: {
			borderRadius: '10px',
			color: theme.palette.neutralSecondary,
			background: theme.palette.neutralLight,
			fontSize: 12,
			padding: '2px 7px',
			marginRight: 5,
			marginBottom: 10,
			whiteSpace: 'nowrap',
		},
	});

const buttonStyles = {
	root: {
		marginRight: 8,
	},
};

const paragraphStackStyles = {
	root: {width: '30%', height: '100%'},
} as IStackStyles;

const requirementStackStyles = {
	root: {width: '70%', height: '100%'},
} as IStackStyles;

const iconButtonStyles = {
	root: {marginTop: '5px'},
};

const searchboxStyles = {
	root: {marginTop: '5px', width: '50%'},
};

const tokens = {
	childrenGap: 8,
};

const getScrollablePaneStyles = (
	overflowX: string,
): Partial<IScrollablePaneStyles> => {
	return {
		root: {
			position: 'relative',
			width: '100%',
			height: '100%',
		},
		contentContainer: {overflowX},
	};
};

const selectedParagraphsScrollablePaneStyles: Partial<IScrollablePaneStyles> =
	getScrollablePaneStyles('hidden');

const assignRequirementsScrollablePaneStyles: Partial<IScrollablePaneStyles> =
	getScrollablePaneStyles('scroll');

const aggregateButtonStyles = {
	root: {
		width: 'fit-content',
	},
} as IButtonStyles;
